import { ApiResponse, useApiFetch, useApiFetchCb } from "./api-util";
import i18n from '../i18n'
import { counter } from "@fortawesome/fontawesome-svg-core";
import { Guid } from 'uuid';



export const useNews = (page: number, pageSize: number): any =>
    useApiFetch('/News/Filtered', { params: { 
        language: i18n.language,
        'Pagination.Page': page,
        'Pagination.ResultPage': pageSize}
    }).data

export const useContacts = (): any =>
    useApiFetch('/Contacts', { params: { 
        language: i18n.language}
    }).data

export const useNewsFeatured = (page: number, pageSize: number): any =>
    useApiFetch('/News/Filtered', { params: { 
        language: i18n.language,
        'Featured': true,
        'Pagination.Page': page,
        'Pagination.ResultPage': pageSize}
    }).data
export const useNewsDetail = (id): any =>
    useApiFetch(`/News/${id}`).data

export const useRankings = (): any =>
    useApiFetch('/Rankings')

export const useMyRankingPosition = (): any =>
    useApiFetch('/Rankings/myposition')

export const useGetPersonalRanking = (): any => useApiFetch('/Customizations/GetAllowPersonalRanking')
export const useGetGeneralRanking = (): any => useApiFetch('/Customizations/GetAllowGeneralRanking')
      
export const useBalances = (): any =>
useApiFetch('/Balances', {
    params: {
        language: i18n.language
    }
}).data
  
export const useOwnRewards = (): any =>
useApiFetch('/RewardsConfiguration/own').data
    
export const useSupplierRewards = (page: number, pageSize: number): any =>
useApiFetch('/RewardsConfiguration/suppliers', {
    params: {
        'Pagination.Page': page,
        'Pagination.ResultPage': pageSize
        }
}).data




export const useAvailableMoney = (): any =>
    useApiFetch('/AgentsManagement/availableMoney').data
      
export const useAccumulatedMoney = (): any =>
    useApiFetch('/AgentsManagement/accumulatedMoney').data
      
export const usePendingCheckout = (): any =>
    useApiFetch('/AgentsManagement/pendingCheckOut').data

export const useReservationStates = (): any =>
    useApiFetch('/Bookings/statuses', {
        params: {
            language: i18n.language
        }
    }).data

export const useReservations = (params: object): any =>{
    
    return useApiFetch('/Bookings/Filtered', {
        params: {
            language: i18n.language,
            ...params
        }
    }).data
}

export const useHotels = (): any =>
    useApiFetch('/Establishments').data

export const useHotelById = (id : string): any =>
useApiFetch(id && `/Establishments/${id}`)?.data

export const useChannels = (): any =>
    useApiFetch('/TTOO').data

export const useRoomTypes = (): any =>
    useApiFetch('/RoomTypes').data

export const useRegimens = (): any =>
    useApiFetch('/Regimens').data

//TODO poner ruta real para obtener si el companyName es mandatory
export const useMandatoryCompanyName = (): any =>
    useApiFetch<any>('/Customizations/mandatoryCompanyName').data

export const useMaxRooms = (): number =>
    useApiFetch<number>('/Customizations/limitnumberrooms').data

export const useMinDate = (): string =>
    useApiFetch<string>('/Customizations/mindatecheckin').data

export const useConfigsList = (): any =>
    useApiFetch('/Configurations').data

export const useConfig = (id: string): any =>
    useApiFetch(id && ('/Configurations/' + id))?.data

export const useConfigDirect = (): any =>
    useApiFetch('/Configurations/directsales').data

export const useLimitsAmount = (): any =>
    useApiFetch('/Rewards/GetRewardsAmountLimits').data

export const useDocumentTypes = (): any =>
    useApiFetch('/AgentsManagement/documentTypes', {
        params: {
            language: i18n.language
        }
    }).data

export const usePositions = (): any =>
    useApiFetch('/AgentsManagement/charges', {
        params: {
            language: i18n.language
        }
    }).data

export const useParticularConditions = (origen: string): any =>{
    return useApiFetch('/Customizations/ParticularConditions', {
        params: {
            language: i18n.language
        }
    }).data
}

export const useLanguages = (): any =>
    useApiFetch('/Languages/webagent').data

export const useCurrentUserLanguage = (): any =>
    useApiFetch('/Agents/language').data


export const useAgencyData = (): any =>
    useApiFetch('/Agencies/data', {
        params: {
            language: i18n.language
        }}).data

export const useRewardsBalanceHistory = (page: number, pageSize: number, orderType: string, filter: number): any =>
    useApiFetch('/Rewards/balance', {
        params: {
            'Pagination.Page': page,
            'Pagination.ResultPage': pageSize,
            'Order.Field': 'date',
            'Order.TypeOrder': orderType,    
            BalanceTypeFilter: filter
            }
    }).data

export const useFilteredAgencies = (name: string, address: string, telephone: string, identityCode: string, page: number, pageSize: number) : any =>
    useApiFetch('/Agencies/filtered', {
    params: {
        'Pagination.Page': page,
        'Pagination.ResultPage': pageSize,
        Name: name,
        Address: address,
        Telephone: telephone,
        IdentityCode: identityCode,
        Language: i18n.language
    }
    }).data

export const useConfirmMailId = (id: string) =>
    useApiFetch(`/AgentsManagement/confirm/${id}`, { method: 'PATCH' })

export const useUserInfo = (): any =>
    useApiFetch('/AgentsManagement', {
        params: {
            language: i18n.language
        }}).data

export const useLandingCustomizations = (): any =>
    useApiFetch('/Customizations/landing', {
        params: {
            language: i18n.language
        }}).data

export const useAdvancedRetribution = (): any =>
    useApiFetch('/AdvancedConfiguration', {
        params: {
            language: i18n.language
        }}).data

// --- Actions ---

export const requestAddChannel = (): (name: string) => Promise<ApiResponse<boolean>> => {
    const fetch = useApiFetchCb()
    // We use "new String" so it gets treated as a json instead of as a string
    return (name: string) => fetch<boolean>('/TTOO/request', { body: new String(name) })
}

export const useAddChannel = (): (name: string) => Promise<ApiResponse<boolean>> => {
    const fetch = useApiFetchCb()
    // We use "new String" so it gets treated as a json instead of as a string
    return (name: string) => fetch<boolean>('/TTOO', { body: new String(name) })
}

export const useAddRegularization = (): (body: object) => Promise<ApiResponse<void>> => {
    const fetch = useApiFetchCb()
    return (body: object) => fetch<void>('/Bookings', { body })
}

export const useNewsScroll = (): (page: number) => Promise<ApiResponse<any>> => {
    const fetch = useApiFetchCb()
    return (page: number) => fetch<any>(`/News/Filtered`, {
        params: {
            'Pagination.ResultPage': 10,
            'Pagination.Page': page,
            language: i18n.language
        }
    })
}

export const useGetReward = (): (body: object) => Promise<ApiResponse<any>> => {
    const fetch = useApiFetchCb()
    return (body: object) => fetch<void>('/Rewards', { body })
}

export const useGetRewards = (): (page: number, pageSize: number, DateFromFrom?: string, 
    DateCreateTo?: string, rewardType?: string, order1field?: string, order1Type?: string,
    order2field?: string, order2Type?: string) => Promise<ApiResponse<any>> => {
    const fetch = useApiFetchCb()

    return  (page: number, pageSize: number, DateFromFrom?: string, 
        DateCreateTo?: string, rewardType?: string, order1field?: string, order1Type?: string,
        order2field?: string, order2Type?: string) => 
    fetch<any>('/Rewards/filtered', {
    params: {
        'Pagination.Page': page,
        'Pagination.ResultPage': pageSize,
        DateFromFrom: DateFromFrom,
        DateCreateTo: DateCreateTo,
        rewardType: rewardType,
        'Order1.Field': order1field,
        'Order1.TypeOrder': order1Type,
        'Order2.Field': order2field,
        'Order2.TypeOrder': order2Type,
        }
    })
}

export const useGetLocations = (): (CountryId?: string, Region1?: string, Region2?: string, Region3?: string,
    Region4?: string) => Promise<ApiResponse<any>> => {
    const fetch = useApiFetchCb()

    return  (CountryId?: string, Region1?: string, Region2?: string, Region3?: string,
        Region4?: string) => 
    fetch<any>('/Locations', {
    params: {
        Language: i18n.language,
        CountryId: CountryId,
        Region1: Region1,
        Region2: Region2,
        Region3: Region3,
        Region4: Region4
        }
    })
}

export const useRecoverCheck = (): (body: object) => Promise<ApiResponse<any>> => {
    const fetch = useApiFetchCb()
    return  (body: object) => 
    fetch<any>('/Rewards/recovery', { body })
}

export const useUpdateProfile = (): (body: object) => Promise<ApiResponse<any>> => {
    const fetch = useApiFetchCb()
    return  (body: object) => 
    fetch<any>(`/AgentsManagement/profile`, { body, method: 'PUT' })
}

export const useUpdatePersonalInfo = (): (body: object) => Promise<ApiResponse<any>> => {
    const fetch = useApiFetchCb()
    return  (body: object) => 
    fetch<any>(`/AgentsManagement`, { body, method: 'PUT' })
}

export const useUpdatePassword = (): (body: object) => Promise<ApiResponse<any>> => {
    const fetch = useApiFetchCb()
    return  (body: object) => 
    fetch<any>(`/AgentsManagement/password`, { body, method: 'PATCH' })
}

export const useUpdateLanguage = (): (language: string) => Promise<ApiResponse<any>> => {
    const fetch = useApiFetchCb()
    return  (language: string) => 
    fetch<any>(`/Agents/language`, { params: { language }, method: 'PUT' })
}

export const useDeleteUser = (): (password: string) => Promise<ApiResponse<any>> => {
    const fetch = useApiFetchCb()
    return  (password: string) => 
    fetch<any>(`/Agents`, { params: { password }, method: 'DELETE' })
}

export const useSendContactForm = (): (body: object) => Promise<ApiResponse<any>> => {
    const fetch = useApiFetchCb()
    return  (body: object) => 
    fetch<any>(`/Contacts/webagent/form`, { body, method: 'POST' })
}

export const useGetMinAllowedNights = (): (body: object) => Promise<ApiResponse<any>> => {
    const fetch = useApiFetchCb()
    return  (body: object) => 
    fetch<any>(`/RewardsConfiguration/GetMinAllowedNights`, { body })
}

export const useGetUserProfile = (): (token?: string) => Promise<ApiResponse<any>> => {
    const fetch = useApiFetchCb()
    return (token?: string) => fetch<any>(`/AgentsManagement/profile/`, { token })
}

export const useGetAvailableUserName = (): (user: string) => Promise<ApiResponse<any>> => {
    const fetch = useApiFetchCb()
    return (user: string) => fetch('/AgentsManagement/user', {
    params: {
        user: user
    }
    })
}

export const useGetAvailableEmail = (): (email: string) => Promise<ApiResponse<any>> => {
    const fetch = useApiFetchCb()
    return (email: string) => fetch('/AgentsManagement/email', {
    params: {
        email: email
    }
    })
}



export const useAddNewAgent = (): (body: object) => Promise<ApiResponse<any>> => {
    const fetch = useApiFetchCb()
    return  (body: object) => 
    fetch<any>(`/AgentsManagement`, { body, method: 'POST' })
}

export const useAddNewAgencies = (): (body: object) => Promise<ApiResponse<any>> => {
    const fetch = useApiFetchCb()
    return  (body: object) => 
    fetch<any>(`/Agencies`, { body, 
        params: { 
            language: i18n.language,
         }, method: 'POST' })
}

export const useResetPassword = (): (email: string) => Promise<ApiResponse<any>> => {
    const fetch = useApiFetchCb()
    return  (email: string) => 
    fetch<any>(`/Agents/password/reset`, { params: { 
        language: i18n.language,
        email: email
     }, method: 'GET' })
}


export const useChangePassword = (): (newPassword: string, token: string) => Promise<ApiResponse<any>> => {
    const fetch = useApiFetchCb()
    return  (newPassword: string, token: string) => 
    fetch<any>(`/AgentsManagement/password/reset`, { body: JSON.stringify(newPassword), 
        headers: { ['X-Authorization'] : token, ['Content-Type']: 'application/json'}, method: 'PATCH' })
}

export const useCurrenciesAll = (): any =>
    useApiFetch('/Currencies/all').data


// --- Actions ---




export const useGetAccountType = (Country: string): any =>{
    
    return useApiFetch('/BankAccount/getBankAccountType', {
        params: {
            Country: Country,           
        }
    }).data
}

export const useGetBankAccount = (AgentId: Guid): any =>{
    
    return useApiFetch('/BankAccount/getBankAccount', {
        params: {
            AgentId: AgentId,           
        }
    }).data
}

export const useGetBankAccountCurrency = (AgentId: Guid): any =>{
    
    return useApiFetch('/BankAccount/getBankAccountCurrency', {
        params: {
            AgentId: AgentId,           
        }
    }).data
}


export const useGetBankAccountCountry = (AgentId: Guid): any =>{
    
    return useApiFetch('/BankAccount/getBankAccountCountry', {
        params: {
            AgentId: AgentId,           
        }
    }).data
}